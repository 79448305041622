<template>
  <div class="smartsearch bg-primary">
    <div class="q-pa-md">
      <div class="row">
        <div class="col column justify-around">
          <q-icon name="fas fa-route" class="fa-2x" />
        </div>
        <div class="col-10">
          <p class="text-weight-medium">
            {{ $t('smartsearch.smartsearch') }}
          </p>
          <p class="text-subtitle1">
            {{ $t('smartsearch.a_to_b') }}
          </p>
          <p class="text-subtitle2">
            {{ $t('smartsearch.plan_your_journey') }}
          </p>
        </div>
      </div>
      <div class="row q-col-gutter-sm q-pt-md">
        <div class="col column">
          <m-location-search
            bg-color="white"
            color="primary-tint"
            @selected="setOrigin"
          />
          <m-location-search
            bg-color="white"
            @selected="setDestination"
          />
        </div>
        <div class="col-1 column justify-around q-pa-xs">
          <q-btn icon="fas fa-sort-alt" flat dense @click="submit" />
        </div>
      </div>
      <div class="smartsearchFooter bg-grey-3 q-py-sm q-px-md" :class="{ 'expanded': smartsearchAdvancedMode }">
        <div v-if="smartsearchAdvancedMode" class="row">
          <div class="col-12">
            <date-field
              v-model="smartsearchParams.depart"
              :min="minDate"
              :label="this.$t('date.travel')"
            />
          </div>
          <div class="col-12">
            <time-field
              v-model="smartsearchParams.depart"
              :min="minDate"
              :label="$t('time.from')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <q-btn
              v-if="!smartsearchAdvancedMode"
              :label="smartsearchLeavingLabel"
              dense
              color="primary"
              flat
              no-caps
              icon="far fa-clock"
              @click="smartsearchAdvancedMode = true"
            />
          </div>
          <div class="col-4">
            <q-btn
              :label="$t('smartsearch.leave_now')"
              dense
              :disabled="!validSmartsearch"
              color="primary"
              class="full-width"
              no-caps
              @click="$store.dispatch('smartsearch/initialQuery'); smartsearchAdvancedMode = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeField from 'components/MFields/time'
import dateField from '@/MFields/date'
import { MLocationSearch } from 'components'
export default {
  name: 'SmartSearchSidebar',
  components: {
    MLocationSearch,
    timeField,
    dateField
  },
  data () {
    return {
      origin: null,
      destination: null
    }
  },
  methods: {
    setOrigin (value) {
      this.origin = value
    },
    setDestination (value) {
      this.destination = value
    },
    submit () {
      this.$emit('submit')
    }
  }

}
</script>

<style  lang="stylus" scoped>

</style>
